@charset "utf-8";
// Write your custom style or overwrite default.scss
// Override Detault style
// DO NOT PUSH this file

.spg-desc:empty {
  transition: all 0.5s ease;
}
header .navbar .mainLogo {
  height: auto;
}
.btn-primary {
  color: #000;
  font-weight: 700;
  &:hover {
    color: #000;
    opacity: 0.8;
  }
}

header .navbar .dropdown-menu .nav-link {
  color: #000;
  font-weight: 700;
}

.navbar-light .navbar-nav .dropdown-menu .nav-link:hover {
  color: #f47a28;
  opacity: 0.8;
}
header {
  .nav-item {
    @media screen and (max-width: 767px) {
      padding: 0 20px;
    }
  }
  .container {
    padding: 0;
  }
  .row {
    margin: 0;
    .col {
      padding: 0;
    }
  }
}
.headerStyle-2 {
  margin: 0;
  padding: 0;
  .logoContainer {
    background-color: #fff200;
    width: 100%;
    overflow: hidden;
    line-height: 0;

    @media screen and (max-width: 992px) {
      margin: 0;

      // .mainLogo {
      //   min-width: 700px;
      // }

      // background-color: transparent !important;

      .navbar-brand {
        padding: 0;
        margin-right: 0;
      }
    }

    @media screen and (max-width: 767px) {
      // .mainLogo {
      //   min-width: 670px;
      // }
    }
    .logo a {
      padding: 0;
      overflow: hidden;
    }

    .banner {
      display: none;
    }
  }
}

header .navbar .dropdown-menu .nav-link.active {
  color: #f47a28;
  font-weight: 700;
}
.navbar-light .navbar-nav .dropdown-menu.show > .nav-link {
  &:hover {
    color: #f47a28;
  }
  color: #000;
}

.mainPage .main-banner {
  margin-bottom: 30px;
}
.navbar-toggler {
  position: absolute;
  top: 30px;
  right: 10px;
  @media screen and (max-width: 767px) {
    top: 20px;
  }

  @media screen and (max-width: 500px) {
    right: 0;
  }
}

#sp_calendar {
  background: #fff;
  border: #f47920 2px solid;
  /* 632C */
  display: inline;
  position: fixed;
  top: 250px;
  right: 20px;
  cursor: pointer;
  padding: 0.5em;
  border-radius: 200px;
  width: 60px;
  z-index: 100;
}

#sp_calendar {
  @media only screen and (min-width: 1700px) {
    right: 12%;
  }
  @media only screen and (max-width: 1023px) {
    top: 215px;
  }
  @media only screen and (max-width: 997px) {
    display: none;
  }
}

.sp_btnLabel {
  display: table-Cell;
  padding: 0em 0.5em;
  vertical-align: middle;
  font-weight: bold;
  display: none !important;
}

.sp_calendarIcon {
  display: table-Cell;
  padding-top: 3px;
  padding-left: 8px;
}

.sp_btngroup {
  visibility: hidden;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

#sp_calendar:hover

/*,
#sp_calendar:focus */
 {
  border-radius: 0px;
  width: auto;
}

#sp_calendar:hover .sp_btngroup

/*,
#sp_calendar:focus .sp_btngroup,
#sp_calendar:focus-within .sp_btngroup */
 {
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  z-index: 1;
  transition-delay: 0s, 0s, 0.3s;
  /* this removes the transition delay so the menu will be visible while the other styles transition */
  margin-top: 0.5em;
  height: auto;
}

#sp_calendar:hover .sp_btnLabel

/*,
#sp_calendar:focus .sp_btnLabel,
#sp_calendar:focus-within .sp_btnLabel */
 {
  display: block !important;
}

#sp_calendar a {
  display: block;
  text-decoration: none;
}

#sp_calendar ul,
#sp_calendar ul li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

#sp_calendar > ul > li {
  display: block;
  border-top: 1px solid #f47920;
  padding: 0.5em;
}

#sp_calendar li:hover {
  background: #cceaef !important;
}
@media screen and (max-width: 1023px) and (min-width: 769) {
  header .nav-item {
    margin-left: 15px;
    .btn-primary {
      padding: 5px 10px;
      border-radius: 15px;
    }
  }
}
